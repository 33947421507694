import { useParams, useNavigate } from "react-router-dom";
import serviceData from "./service_constants";
import { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import "./service.css";

function Service() {
    const params = useParams();
    const navigate = useNavigate();
    const [currentServiceId, setCurrentServiceId] = useState(1);

    if (!params.id) {
        return <></>;
    }

    const group = serviceData.services.find((group) => params.id && group.id === +params?.id);

    const getServices = () => {
        return group?.services.find((x) => x.id === currentServiceId);
    };

    return (
        <main className="service-detail-page" id="service-detail-page">
            <div className="container">
                <h1 className="page-title">
                    <button className="service-detail-back fs-500 text-blue bg-white" onClick={() => navigate("/services")}>
                        <FontAwesomeIcon icon={faArrowLeft} />
                    </button>
                    {group?.name}
                </h1>
                <div className="service-detail flex">
                    <ul className="service-sidebar">
                        {group?.services.map((service, index) => (
                            <li
                                className={"bg-blue text-white" + (currentServiceId === service.id ? " active" : "")}
                                onClick={() => setCurrentServiceId(index + 1)}
                            >
                                {service.name}
                            </li>
                        ))}
                    </ul>
                    <div>
                        <h2>{getServices()?.name}</h2>
                        <ul className="service-points fs-300">
                            {getServices()?.detail.map((service) => (
                                <li>{service}</li>
                            ))}
                        </ul>
                    </div>
                </div>
            </div>
        </main>
    );
}

export default Service;
