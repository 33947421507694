import "./home.css";
import electrical from "../../assets/home/electrical-min.jpg";
import electricalWebp from "../../assets/home/electrical.webp";
import instrumentation from "../../assets/home/instrumentation-min.jpg";
import instrumentationWebp from "../../assets/home/instrumentation.webp";
import automation from "../../assets/home/automation-min.jpg";
import automationWebp from "../../assets/home/automation.webp";
import OwlCarousel from "react-owl-carousel";
import { carouselImages } from "./home_constants";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faListCheck, faHelmetSafety, faPaintRoller, faScrewdriverWrench } from "@fortawesome/free-solid-svg-icons";

const options = {
    loop: true,
    margin: 10,
    nav: true,
    navText: ["<", ">"],
    responsive: {
        0: {
            items: 1,
        },
        600: {
            items: 3,
        },
        1000: {
            items: 5,
        },
    },
};

function Home() {
    return (
        <main className="home" id="home">
            <div className="slider">
                <div className="slider-text text-light-dark uppercase fs-700">
                    <p style={{ fontWeight: 550 }}>We are a leading</p>
                    <p style={{ fontWeight: 550 }}>Construction Company</p>
                </div>
            </div>
            <div className="services-industries container">
                <div className="home-services flex">
                    <div className="home-service bg-blue text-white blue-box-shadow electrical">
                        <picture>
                            <source srcSet={electricalWebp} type="image/webp" className="home-service-image" />
                            <img src={electrical} alt="electrical" className="home-service-image" />
                        </picture>
                        <div className="home-service-body">
                            <div className="home-service-body-header">
                                <p className="home-service-title uppercase">Electrical</p>
                                <p className="home-service-content fs-300">
                                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Id et euismod bibendum
                                    adipiscing et orci, fermentum.
                                </p>
                            </div>
                            <div className="home-service-body-footer">
                                <a className="service-read-more text-blue bg-white" href="/services">
                                    LEARN MORE
                                </a>
                            </div>
                        </div>
                    </div>
                    <div className="home-service bg-blue text-white blue-box-shadow instrumentation">
                        <picture>
                            <source srcSet={instrumentationWebp} type="image/webp" className="home-service-image" />
                            <img src={instrumentation} alt="instrumentation" className="home-service-image" />
                        </picture>
                        <div className="home-service-body">
                            <div className="home-service-body-header">
                                <p className="home-service-title uppercase">Instrumentation</p>
                                <p className="home-service-content fs-300">
                                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Id et euismod bibendum
                                    adipiscing et orci, fermentum.
                                </p>
                            </div>
                            <div className="home-service-body-footer">
                                <a className="service-read-more text-blue bg-white" href="/services">
                                    LEARN MORE
                                </a>
                            </div>
                        </div>
                    </div>
                    <div className="home-service bg-blue text-white blue-box-shadow automation">
                        <picture>
                            <source srcSet={automationWebp} type="image/webp" className="home-service-image" />
                            <img src={automation} alt="automation" className="home-service-image" />
                        </picture>
                        <div className="home-service-body">
                            <div className="home-service-body-header">
                                <p className="home-service-title uppercase">Automation</p>
                                <p className="home-service-content fs-300">
                                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Id et euismod bibendum
                                    adipiscing et orci, fermentum.
                                </p>
                            </div>
                            <div className="home-service-body-footer">
                                <a className="service-read-more text-blue bg-white" href="/services">
                                    LEARN MORE
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="industries">
                    <h2 className="industries-heading">Industries we are working with</h2>
                    <div className="flex carousel">
                        <OwlCarousel className="slider-items owl-carousel" {...options}>
                            {carouselImages.map((image, index) => (
                                <div className="item slider-item underline-indicators" key={index}>
                                    <picture>
                                        <source srcSet={image.webp} type="image/webp" className="carousel-image" />
                                        <img src={image.src} alt={image.title} className="carousel-image" />
                                    </picture>
                                    <p className="slider-item-text fs-200">{image.title}</p>
                                </div>
                            ))}
                        </OwlCarousel>
                    </div>
                </div>
            </div>

            <div className="our-services-module bg-white">
                <h2 className="our-services-heading">Our Services</h2>
                <div className="our-services container grid">
                    <div className="our-service-item flex">
                        <div className="our-service bg-light-gray our-service-1 flex">
                            <div className="service-icons text-blue fs-800">
                                <FontAwesomeIcon icon={faListCheck} />
                            </div>
                            <div className="our-service-title fs-500">Management</div>
                            <div className="our-service-body fs-300">
                                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Id et euismod bibendum adipiscing et
                                orci, fermentum.
                            </div>
                        </div>
                        <div className="our-service bg-light-gray our-service-2 flex">
                            <div className="service-icons text-blue fs-800">
                                <FontAwesomeIcon icon={faHelmetSafety} />
                            </div>
                            <div className="our-service-title fs-500">Engineering</div>
                            <div className="our-service-body fs-300">
                                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Id et euismod bibendum adipiscing et
                                orci, fermentum.
                            </div>
                        </div>
                    </div>
                    <div className="our-service-item flex">
                        <div className="our-service bg-light-gray our-service-3 flex">
                            <div className="service-icons text-blue fs-800">
                                <FontAwesomeIcon icon={faPaintRoller} />
                            </div>
                            <div className="our-service-title fs-500">Renovation</div>
                            <div className="our-service-body fs-300">
                                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Id et euismod bibendum adipiscing et
                                orci, fermentum.
                            </div>
                        </div>
                        <div className="our-service bg-light-gray our-service-4 flex">
                            <div className="service-icons text-blue fs-800 ">
                                <FontAwesomeIcon icon={faScrewdriverWrench} />
                            </div>
                            <div className="our-service-title fs-500">Maintenance</div>
                            <div className="our-service-body fs-300">
                                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Id et euismod bibendum adipiscing et
                                orci, fermentum.
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </main>
    );
}

export default Home;
