import React, { useState, useEffect, useRef } from "react";
import logo from "../../logo.png";
import { NavLink, useLocation } from "react-router-dom";
import "./nav.css";

const Navbar = () => {
    const [isNavExpanded, setIsNavExpanded] = useState(false);
    const navRef = useRef<HTMLDivElement>(null);
    const btnRef = useRef<HTMLButtonElement>(null);

    useEffect(() => {
        const handler = (e: MouseEvent) => {
            const target = e.target as HTMLDivElement;
            if (!navRef.current?.contains(target) && !btnRef.current?.contains(target)) {
                setIsNavExpanded(false);
            }
        };

        document.addEventListener("mousedown", handler);
    }, []);

    const location = useLocation();
    const homeActive = location.pathname === "/" || location.pathname === "/home";
    const contactActive = location.pathname === "/contact";
    const servicesActive = location.pathname.startsWith("/service");

    return (
        <header>
            <div className="flex navbar fs-300">
                <div className="nav-left">
                    <img src={logo} alt="Logo" className="logo" height={100} width={100}></img>
                </div>
                <button
                    className="mobile-nav-toggle bg-blue"
                    aria-controls="primary-nav"
                    onClick={() => {
                        setIsNavExpanded(!isNavExpanded);
                    }}
                    ref={btnRef}
                >
                    <span className="sr-only">Menu</span>
                    {isNavExpanded ? (
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="white" className="w-6 h-6">
                            <path
                                fillRule="evenodd"
                                d="M5.47 5.47a.75.75 0 011.06 0L12 10.94l5.47-5.47a.75.75 0 111.06 1.06L13.06 12l5.47 5.47a.75.75 0 11-1.06 1.06L12 13.06l-5.47 5.47a.75.75 0 01-1.06-1.06L10.94 12 5.47 6.53a.75.75 0 010-1.06z"
                                clipRule="evenodd"
                            />
                        </svg>
                    ) : (
                        <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" viewBox="0 0 20 20" fill="white">
                            <path
                                fillRule="evenodd"
                                d="M3 5a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 10a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM9 15a1 1 0 011-1h6a1 1 0 110 2h-6a1 1 0 01-1-1z"
                                clipRule="evenodd"
                            />
                        </svg>
                    )}
                </button>
                <nav className="nav-center" ref={navRef}>
                    <ul
                        id="primary-nav"
                        data-visible={isNavExpanded}
                        className={"primary-nav text-dark uppercase letter-spacing-2 flex"}
                    >
                        <li className="underline-indicators" data-active={homeActive}>
                            <NavLink
                                className="uppercase text-dark letter-spacing-2 nav-anchor"
                                to="/home"
                                onClick={() => {
                                    setIsNavExpanded(!isNavExpanded);
                                }}
                            >
                                Home
                            </NavLink>
                        </li>
                        {/* <li className="underline-indicators">
                            <NavLink className="uppercase text-dark letter-spacing-2" to="#">
                                About Us
                            </NavLink>
                        </li> */}
                        {/* <li className="underline-indicators">
                            <NavLink className="uppercase text-dark letter-spacing-2" to="#">
                                Certification & Awards
                            </NavLink>
                        </li> */}
                        <li
                            className="underline-indicators"
                            data-active={servicesActive}
                            onClick={() => {
                                setIsNavExpanded(!isNavExpanded);
                            }}
                        >
                            <NavLink className="uppercase text-dark letter-spacing-2 nav-anchor" to="/services">
                                Services
                            </NavLink>
                        </li>
                        {/* <li className="underline-indicators">
                            <NavLink className="uppercase text-dark letter-spacing-2" to="#">
                                Projects
                            </NavLink>
                        </li> */}
                        {/* <li className="underline-indicators">
                            <NavLink className="uppercase text-dark letter-spacing-2" to="#">
                                Careers
                            </NavLink>
                        </li> */}
                        <li
                            className="underline-indicators"
                            data-active={contactActive}
                            onClick={() => {
                                setIsNavExpanded(!isNavExpanded);
                            }}
                        >
                            <NavLink className="uppercase text-dark letter-spacing-2 nav-anchor" to="/contact">
                                Contact
                            </NavLink>
                        </li>
                    </ul>
                </nav>
            </div>
        </header>
    );
};

export default Navbar;
