import mep from "../../assets/home/carousel/mep.jpeg";
import mepwebp from "../../assets/home/carousel/mep.webp";
import cement_ceramic from "../../assets/home/carousel/cement_ceramic.jpeg";
import cement_ceramicwebp from "../../assets/home/carousel/cement_ceramic.webp";
import food from "../../assets/home/carousel/food.jpeg";
import foodwebp from "../../assets/home/carousel/food.webp";
import minerals_mining from "../../assets/home/carousel/minerals_mining.png";
import minerals_miningwebp from "../../assets/home/carousel/minerals_mining.webp";
import oil_gas from "../../assets/home/carousel/oil_gas.jpeg";
import oil_gaswebp from "../../assets/home/carousel/oil_gas.webp";
import petrolchemical from "../../assets/home/carousel/petrolchemical.jpeg";
import petrolchemicalwebp from "../../assets/home/carousel/petrolchemical.webp";
import power_generation from "../../assets/home/carousel/power_generation.jpeg";
import power_generationwebp from "../../assets/home/carousel/power_generation.webp";
import steel from "../../assets/home/carousel/steel.jpeg";
import steelwebp from "../../assets/home/carousel/steel.webp";
import textile from "../../assets/home/carousel/textile.jpeg";
import textilewebp from "../../assets/home/carousel/textile.webp";
import water from "../../assets/home/carousel/water.jpg";
import waterwebp from "../../assets/home/carousel/water.webp";

const carouselImages = [
    {
        title: "Minerals & Mining Industry",
        src: minerals_mining,
        webp: minerals_miningwebp,
    },
    {
        title: "Petrochemical & Allied Industry",
        src: petrolchemical,
        webp: petrolchemicalwebp,
    },
    {
        title: "Oil & Gas Industry",
        src: oil_gas,
        webp: oil_gaswebp,
    },
    {
        title: "Buildings MEP",
        src: mep,
        webp: mepwebp,
    },
    {
        title: "Power Generation Utilities",
        src: power_generation,
        webp: power_generationwebp,
    },
    {
        title: "Water & waste Water Utilities",
        src: water,
        webp: waterwebp,
    },
    {
        title: "Cement & Ceramic Industry",
        src: cement_ceramic,
        webp: cement_ceramicwebp,
    },
    {
        title: "Food & Beverages Industry",
        src: food,
        webp: foodwebp,
    },
    {
        title: "Steel & Alloy Industry",
        src: steel,
        webp: steelwebp,
    },
    {
        title: "Textile Industry",
        src: textile,
        webp: textilewebp,
    },
];

export { carouselImages };
