import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faPhoneVolume,
    faMapLocationDot,
    faEnvelope,
    faPaperPlane,
    faUser,
    faPhone,
    faEnvelopeCircleCheck,
} from "@fortawesome/free-solid-svg-icons";
import "./contact.css";

function Contact() {
    return (
        <main className="contact-page" id="contact-page">
            <div className="container">
                <h1 className="page-title">Contact Us</h1>
                <form className="contact-form bg-light-gray dark-box-shadow">
                    <div className="contact-form-heading">
                        <h2>Free free to contact</h2>
                        <h2>us for any query</h2>
                    </div>
                    <div className="contact-form-div flex">
                        <div className="contact-information">
                            <div className="grid">
                                <div className="contact-info contact-phone flex">
                                    <div className="contact-icon-div">
                                        <FontAwesomeIcon icon={faPhoneVolume} className="contact-icon bg-blue text-white" />
                                    </div>
                                    <div className="contact-info-content">
                                        <p className="contact-info-heading">Office</p>
                                        <p>+966 12 607 7884</p>
                                    </div>
                                </div>
                                <div className="contact-info contact-mail-address flex">
                                    <div className="contact-icon-div">
                                        <FontAwesomeIcon icon={faEnvelope} className="contact-icon bg-blue text-white" />
                                    </div>
                                    <div className="contact-info-content">
                                        <p className="contact-info-heading">Mail Address</p>
                                        <p>contact@nafeh-khaleej.com</p>
                                    </div>
                                </div>
                                <div className="contact-info contact-office-address flex">
                                    <div className="contact-icon-div">
                                        <FontAwesomeIcon icon={faMapLocationDot} className="contact-icon bg-blue text-white" />
                                    </div>
                                    <div className="contact-info-content">
                                        <p className="contact-info-heading">Office Address</p>
                                        <p>Office # 08, 2nd Floor, Business Centre (7116) As-safa Dist.</p>
                                        <p>P.O. Box 23453, Jeddah-Kingdom of Saudi Arabia</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="contact-inputs">
                            <div className="contact-input-grid grid">
                                <div className="contact-input flex">
                                    <div className="input-container">
                                        <input placeholder="*First Name" className="input-first-name bg-light-gray" />
                                        <FontAwesomeIcon icon={faUser} className="input-icon text-gray" />
                                    </div>
                                    <div className="input-container">
                                        <input placeholder="*Last Name" className="input-last-name bg-light-gray" />
                                        <FontAwesomeIcon icon={faUser} className="input-icon text-gray" />
                                    </div>
                                </div>
                                <div className="contact-input flex">
                                    <div className="input-container">
                                        <input placeholder="*E-Mail Address" className="input-email bg-light-gray" />
                                        <FontAwesomeIcon icon={faEnvelopeCircleCheck} className="input-icon text-gray" />
                                    </div>
                                    <div className="input-container">
                                        <input placeholder="*Phone Number" className="input-phone bg-light-gray" />
                                        <FontAwesomeIcon icon={faPhone} className="input-icon text-gray" />
                                    </div>
                                </div>
                                <div>
                                    <textarea placeholder="Enter Message" className="input-message bg-light-gray" />
                                </div>
                                <div className="input-submit-div">
                                    <button className="input-submit bg-blue text-white blue-box-shadow">
                                        Submit <FontAwesomeIcon icon={faPaperPlane} />
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
            </div>

            <iframe
                src="https://www.google.com/maps/embed?pb=!1m17!1m12!1m3!1d3710.3454824720075!2d39.227218575271586!3d21.572433480216457!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m2!1m1!2zMjHCsDM0JzIwLjgiTiAzOcKwMTMnNDcuMyJF!5e0!3m2!1sen!2s!4v1693311709496!5m2!1sen!2s"
                loading="lazy"
                title="office location"
                className="contact-map-location"
            ></iframe>
        </main>
    );
}

export default Contact;
