import "./footer.css";
import logo from "../../logo.png";

function Footer() {
    return (
        <footer className="footer bg-accent flex">
            <div className="logo-footer flex">
                <img src={logo} alt="logo" className="footer-logo"></img>
                <div className="footer-company">
                    <p className="footer-company-text">Nafeh Al Khaleej</p>
                    <p className="footer-company-description">CONTRACTING CO. LTD.</p>
                </div>
            </div>
            <div className="footer-right flex">
                <div className="contact-info text-dark fs-300">
                    <p className="contact-info-title fs-400">Contact Info</p>
                    <p>Office # 08, 2nd Floor ,Business Centre (7116) As-safa Dist.</p>
                    <p>P.O. Box 23453, Jeddah-Kingdom of Saudi Arabia</p>
                    <p>
                        <span className="contact-span">Email</span> contact@nafeh-khaleej.com
                    </p>
                    <p>
                        <span className="contact-span">Tel</span> +966 12 607 7884
                    </p>
                </div>
                <div className="map-location">
                    <iframe
                        src="https://www.google.com/maps/embed?pb=!1m17!1m12!1m3!1d3710.3454824720075!2d39.227218575271586!3d21.572433480216457!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m2!1m1!2zMjHCsDM0JzIwLjgiTiAzOcKwMTMnNDcuMyJF!5e0!3m2!1sen!2s!4v1693311709496!5m2!1sen!2s"
                        loading="lazy"
                        title="office location"
                        className="office-location"
                    ></iframe>
                </div>
            </div>
        </footer>
    );
}

export default Footer;
