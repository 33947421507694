import { useNavigate } from "react-router-dom";
import "./services.css";
import servicesData from "./service_constants";

function Services() {
    const navigate = useNavigate();

    const switchRoute = (id: number) => {
        navigate(`/service/${id}`);
    };

    return (
        <main className="services" id="services">
            <div className="container">
                <h1 className="page-title">Services</h1>
            </div>
            {servicesData.services.map((group, index) => (
                <section className={"full-width-split-screen " + (index % 2 === 0 ? "bg-yellow" : "bg-blue text-white")}>
                    {index % 2 === 0 && <img src={group.image} alt={group.name} />}
                    <div>
                        <h2>{group.name}</h2>
                        <p>{group.description}</p>
                        <button className="view-detail-btn" onClick={() => switchRoute(group.id)}>
                            View Details {">"}
                        </button>
                    </div>
                    {index % 2 !== 0 && <img src={group.image} alt={group.name} />}
                </section>
            ))}
        </main>
    );
}

export default Services;
