import feasibility from "../../assets/services/feasibility_study-min.jpg";
import feasibilityWebp from "../../assets/services/feasibility_study.webp";
import procurement from "../../assets/services/procurement-min.jpg";
import procurementWebp from "../../assets/services/procurement.webp";
import commissioning from "../../assets/services/commissioning-min.jpg";
import commissioningWebp from "../../assets/services/commissioning.webp";
import industrial_automation from "../../assets/services/industrial_automation-min.jpg";
import industrial_automationWebp from "../../assets/services/industrial_automation.webp";

const data = {
    services: [
        {
            id: 1,
            name: "Feasibility Study & Engineering Services E&M",
            description: [
                "Feasibility Study covers optimization, safety, cost analysis, and financial profitability " +
                    "assessments. Engineering Design Support includes conceptual design, equipment sizing, electrical " +
                    "studies, and safety compliance. From equipment selection to approval follow-up and F&G protection, " +
                    "our services ensure comprehensive solutions for your projects.",
            ],
            image: feasibility,
            webp: feasibilityWebp,
            services: [
                {
                    id: 1,
                    name: "TECHNICAL FEASIBILITY STUDY",
                    detail: [
                        "Technical optimization services.",
                        "Environmental & safety studies.",
                        "Risk analysis.",
                        "Capital & operating cost estimation.",
                        "Economical & financial profitability studies.",
                    ],
                    images: [],
                },
                {
                    id: 2,
                    name: "ENGINEERING DESIGN SUPPORT",
                    detail: [
                        "Conceptual & base design.",
                        "Front end engineering design for Electrical-Mechanical equipment.",
                        "E&M equipment sizing including tanks, piping, heat exchanger, cooling towers skids MV/LV cables, transformers, switchgears.",
                        "Electrical load study and coordination study using ETAP tools.",
                        "Control philosophy, cause & effect sheet & SAMA logic building.",
                        "Electrical design for LV & MV equipment including MV VFDs, DC bank & Industrial UPS.",
                        "Instrumentation & control design including DCS/PLC panels.",
                        "Approval follow-up with client and CRS rectification.",
                        "ATEX & NEMA classified equipment selection according to zone classification.",
                        "F&G detection & protection.",
                        "IFC drawing for E&I equipment installation.",
                        "ILDs for E&I equipment interconnection.",
                    ],
                    images: [],
                },
            ],
        },
        {
            id: 2,
            name: "Procurement & Construction Services E&M",
            description: [
                "Our Procurement Management covers vendor qualification, technical and commercial evaluations, " +
                    "purchase orders, inspection, and project cost control. In Project Execution & Erection, we handle " +
                    "mobilization, on-site construction, equipment installation, metalwork, electrical equipment, cable " +
                    "trays, and more. Testing & Pre-Commissioning includes equipment inspection, electrical testing, " +
                    "protection settings, and comprehensive support. We ensure the highest quality through FAT " +
                    "inspections, calibration, and thorough testing.",
            ],
            image: procurement,
            webp: procurementWebp,
            services: [
                {
                    id: 1,
                    name: "PROCUREMENT MANAGEMENT",
                    detail: [
                        "Vendor qualification & subcontractors assessment.",
                        "Technical & commercial evaluation.",
                        "Purchase order placement & contract administration.",
                        "Inspection & quality control.",
                        "Project cost control.",
                    ],
                    images: [],
                },
                {
                    id: 2,
                    name: "PROJECT EXECUTION & ERECTION",
                    detail: [
                        "Mobilization of manpower, equipment & tools including SITE facilities buildup.",
                        "On site construction of electrical, Mechanical, instrumentation & control facilities as per approved method statement.",
                        "Dismantling old equipment & installation of new equipment in E&M facilities including steel structure & piping work.",
                        "Fuel skids, Instruments & Mechanical piping system, heat exchanger ,cooling/heating system installation including fabrication & welding work.",
                        "All kind of metal works, Steel Gratings, Caged Ladders, Steel Platforms, handrails & Structure including fabrication & Installation..",
                        "Switchgear, transformers, MCC,HVAC & other electrical-Mechanical equipment installation & extension of existing switchgears / facilities earthing & lighting work.",
                        "Cable tray installation including power & control cable laying.",
                        "MV/LV power cable termination under SEC license.",
                        "Field instrument  including junction boxes and DCS/PLC panel installation.",
                        "All kind of metal works, Steel Gratings, Caged Ladders, Steel Platforms, handrails & Structure including fabrication & Installation..",
                        "FAT inspection on behalf of client in OEM facilities.",
                        "Fire devices ,FACP, lighting panel & outdoor socket installation.",
                    ],
                    images: [],
                },
            ],
        },
        {
            id: 3,
            name: "Pre-COMM, Commissioning & Startup Services E&M",
            description: [
                "Testing & Pre-Commissioning services involve comprehensive inspections, electrical testing, " +
                    "protection setting, and support for pre-commissioning tasks. We ensure precision in various tests on " +
                    "equipment like transformers, switchgears, cables, and motors. Commissioning & Startup services " +
                    "encompass a wide array of activities, from procedures and energization to safety checks and technical " +
                    "support. We prioritize reliability and meticulous documentation, including O&M manuals, for a smooth " +
                    "transition from construction to operation.",
            ],
            image: commissioning,
            webp: commissioningWebp,
            services: [
                {
                    id: 1,
                    name: "TESTING & PRE-COMMISSIONING",
                    detail: [
                        "Inspection of equipment Installation in E&M facility according to P&IDs, PFDs & SLDs.",
                        "Mechanical equipment ,Electrical panels, JBs & Instruments installation inspection.",
                        "MV/LV Protection setting according to approved document.",
                        "Specific electrical test(Megger, HIPOT, Turn Ratio, pole resistance, Tan delta, primary & secondary injection) to be performed on  equipment like transformer MV/LV switchgears, cables, motors etc.",
                        "Pre-Commissioning support to vendors & OEM engineers.",
                        "Cold loop testing for field instruments & electrical panels.",
                        "Motor vibration test, NDT & Hydro testing.",
                        "RFI raising for each task inspection.",
                        "On SITE calibration of field instruments.",
                    ],
                    images: [],
                },
                {
                    id: 2,
                    name: "COMMISSIONING & STARTUP",
                    detail: [
                        "Commissioning procedure & energization.",
                        "Follow PTW for any re-work.",
                        "Pressure test & regulation, leakage ,vibration and load test.",
                        "Hot loop testing & rotary machine alignment.",
                        "Field instrument & actuators function test.",
                        "Electrical Equipment functional test & safety interlock including transformers, switchgears, motors, UPS etc.",
                        "Package commissioning & process interlock.",
                        "PID Loops tuning.",
                        "Alarm & Tripping verification.",
                        "Technical support to OEM during commissioning.",
                        "Reliability Test Run for equipment/plant.",
                        "Final punch list rectification.",
                        "As Build documentation including O&M manuals.",
                    ],
                    images: [],
                },
            ],
        },
        {
            id: 4,
            name: "Industrial Automation & System Integration",
            description: [
                "We offer comprehensive support for a wide range of equipment and systems, including PLC and SCADA " +
                    "systems, control system upgrades, VFDs, industrial instruments, UPS systems, E&I work, hazardous area " +
                    "equipment, and F&G detection systems.",
                "We also provide specialized services for various gas turbine and DCS systems, including control logic " +
                    "review, hardware supply and configuration, and thorough testing and commissioning, ensuring the " +
                    "highest standards of precision and reliability in industrial automation.",
            ],
            image: industrial_automation,
            webp: industrial_automationWebp,
            services: [
                {
                    id: 1,
                    name: "COMPLETE SUPPORT FOR BELOW EQUIPMENT & SYSTEMS",
                    detail: [
                        "PLC & SCADA system including implementation of cause & effect matrix chart, programming & software development and site integrity test (SIT) for SIEMENS & ALLAN BRADLEY platforms.",
                        "Upgradation & modification of existing equipment/plant control systems.",
                        "LV & MV variable frequency drive (VFD) for motors being used in different industrial application.",
                        "Industrial instrument like PT,TT,FT level gauges etc.",
                        "UPS system for critical industrial loads.",
                        "E&I work (LV& MV) for diesel engine driven pumps & black start generators including their dedicated controller programming, load sharing & synchronization.",
                        "Hazardous area classified equipment as per ATEX/NEMA/UL/IECEX standard for required zone/Class.",
                        "F&G detection system for buildings & industries.",
                    ],
                    images: [],
                },
                {
                    id: 2,
                    name: "OUR SPECIALIZED SERVICES",
                    detail: [
                        "GE MARK V , MARK VI & MARK Vie Gas Turbine Control System.",
                        "GE MARK Vie Distributed Control System(DCS).",
                        "Emerson Ovation 2.3 & 3.1 Distributed Control System (DCS).",
                        "ABB 800XA Distributed Control System (DCS).",
                        "Technical assistance to Gas Turbine GE-MS7001EA & GE-MS600B.",
                        "Technical assistance to Gas Turbine Westinghouse W501D.",
                        "Review control philosophy & collect system backups.",
                        "Implementation of new control logic for additional points & necessary modification in existing logic.",
                        "New hardware supply, installation & configuration for new points if spare not available.",
                        "Offline test for newly implemented control loops.",
                        "Online testing & commissioning with submittal handover.",
                    ],
                    images: [],
                },
            ],
        },
    ],
};
export default data;
